import React, { useEffect } from 'react';
import './Service.css'; // Add custom styles in this CSS file
import Footer from './Footer';
const Service = () => {
 
  return (
<div>
<div className='about-header'style={{ backgroundColor: "#7e84ec"}}>

</div>
<div className='service-final-content'>
<div class="button-header">

    <h2 className='button-content'>Web Designing</h2>
    <h2 className='button-content-1'>App Designing</h2>
    <h2 className='button-content-2'>Product Sourcing</h2>
    <h2 className='button-content-3'>Content Creation</h2>
    <h2 className='button-content-4'>Branding</h2>
    <h2 className='button-content-5'>Packaging Solution</h2>
    <h2 className='button-content-6'>Product Photography</h2>
    <h2 className='button-content-7'>Product Videography</h2>
    <h2 className='button-content-8'>Advertising</h2>
  </div>
</div>
<Footer/>
</div>
  

  );
};

export default Service;
