import React from 'react';
import './About.css';
import who from './images/who-we-are-3.webp';
import how from './images/how-we-work.png';
import what from './images/what-we-do-1.webp'
import Footer from './Footer';
function About(){
    return(<div >
        <div className='about-header' style={{ backgroundColor: "#7e84ec" }}>

        </div>
          {/* <center><h1 className='about-header-content'>About Us</h1></center>  */}
      <div className='bubbles'>
       <div className='bubble'>
       <center> <h2>Our Mission:</h2></center>
        <p>
Our mission is to turn your business vision into a reality with tailored 
solutions designed to elevate your brand presence and boost customer engagement.
</p>
       </div>  <div className='bubble'>
       <center><h2>Our Vision:</h2></center>
        <p>
        To be a trusted partner for businesses across industries by
         offering cutting-edge solutions that transform ideas into success stories.</p>
       </div></div>
          <div className='about-content-img'>
              <div className='about-zig'><div><center><h1>WHO WE ARE</h1></center>
              <p>At Three Dreams Ecommerce Pvt Ltd, we believe that every business has a unique story to tell,
                 and our mission is to help bring those stories to life. As a dynamic team of designers, marketers, 
                 and strategists, 
                we specialize in providing innovative solutions that transform ideas into impactful digital experiences.</p></div>
              <img src={who} alt="three dreams about image" class="about-zig-image"/>
              </div>
              <div className='about-zig'>
              <img src={how} alt="three dreams about image" class="about-zig-image-2"/>
              <div className='zig-zax'><center><h1>HOW WE WORK</h1></center>
              <p>We don’t just provide services; we build partnerships. Our team of creative professionals is
                 dedicated to understanding your business and delivering solutions that are aligned with your goals. 
                 With a focus on quality, 
                innovation, and results, we work hand in hand with our clients to achieve measurable growth.</p></div>
              </div>
              <div className='about-zig'><div><center><h1>WHAT WE DO</h1></center>
              <p>Wide Range of Services: From custom web design to SEO-optimized content and digital advertising,
                 we offer all the tools your business needs to succeed under one roof.
Tailored Solutions: Every business is unique, and so are our services. We develop strategies that are specifically
 designed to meet your business goals and deliver measurable results.
Customer-Centric Approach: Your success is our success. We are committed to providing top-notch customer 
service and delivering projects on time and within budget.
Our Core Values:</p></div>
              <img src={what} alt="three dreams about image" class="about-zig-image"/>
              </div>
          </div>
       <Footer/>
  </div>);

}
export default About;