import React from 'react';
import about_img from './images/contact-us-bg.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Contact.css';
import Footer from './Footer';

function Contact() {
  const clearForm = (e) => {
    e.preventDefault();
    document.getElementById("contact-form").reset(); // This will reset all the form fields
  };

  return (
    <div>
      <div className='about-header' style={{ backgroundColor: "#7e84ec" }}></div>

      <div class="ser-img"></div>

      <section id="contact">
        <div className="container-fluid px-5 tagline">
          <br />
          <h3 class="description-1">
            We’re here to help you turn your ideas into reality. Whether you have a question, need a consultation, 
            or want to discuss your next project, we’re just a message away. At Three Dreams Ecommerce, we value open
            communication and are committed to providing you with the support and solutions you need to succeed.
          </h3>

          <div className="row1 description">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12 col-lg-6 bg-white">
                  <br />
                  <h4>We love to hear from you</h4>
                  <form 
                    id="contact-form"
                    action="https://formspree.io/f/xwpkpzrd" 
                    method="POST"
                  >
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" className="form-control" name="user_name" required />
                    </div>
                    <div className="form-group">
                      <label>Company Name</label>
                      <input type="text" className="form-control" name="companyName" />
                    </div>
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input type="text" className="form-control" name="mobileNumber" />
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <input type="email" className="form-control" name="user_email" required />
                    </div>
                    <div className="form-group">
                      <label>Address</label>
                      <input type="text" className="form-control" name="address" />
                    </div>
                    <div className="form-group">
                      <label>Subject</label>
                      <input type="text" className="form-control" name="subject" />
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <textarea className="form-control" name="message" cols="5" rows="5" required></textarea>
                    </div>
                    <div className="pad">
                      <button className="btn btn-primary my-2" type="submit">
                        Submit
                      </button>
                      <button className="btn btn-primary my-2" onClick={clearForm}>
                        Clear
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
<div className='locations-another'>
            <h1 className="locations">Our Locations</h1></div>
            <div className="map">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497511.23105803586!2d79.87932965728102!3d13.047985947000162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1721899682958!5m2!1sen!2sin"
                  width="250"
                  height="150"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="chennai"
                ></iframe>
                <h2 className="mb-3">India</h2>
                <p>No #13/53, Rams Apartment, 3rd Floor, Ashok Nagar, Chennai - 600 083</p>
              </div>

              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d939321.3154741916!2d112.5683111542258!3d23.125490299758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3402f895a35c2bc7%3A0xe59e075adeae415!2sGuangzhou%2C%20Guangdong%20Province%2C%20China!5e0!3m2!1sen!2sin!4v1721899817701!5m2!1sen!2sin"
                  width="240"
                  height="145"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="china"
                ></iframe>
                <h2 className="mb-3">China</h2>
                <p>No 202, Wanbo er Rd, Nacun Town, Panyu, Guangzhou, China 510000</p>
              </div>

              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1003450.3275705299!2d106.03566945039915!3d10.75544596140221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529292e8d3dd1%3A0xf15f5aad773c112b!2sHo%20Chi%20Minh%20City%2C%20Vietnam!5e0!3m2!1sen!2sin!4v1721901597420!5m2!1sen!2sin"
                  width="240"
                  height="145"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="vietnam"
                ></iframe>
                <h2 className="mb-3">Vietnam</h2>
                <p>183/32B, Ben Van Don, Ward 6, District 4, Ho Chi Minh City, Vietnam</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Contact;
